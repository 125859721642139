



const Roadmap: React.FC = () => {






    return (
        <div className="roadmap h-96 md:h-[80vh] relative w-full py-8 flex justify-center items-center">
        </div>
    )
}



export default Roadmap;